.deliveryContainer {
  display: flex;
  width: 100%;
  padding: 1rem;
  height: 100%;
  flex-flow: column wrap;
  justify-content: center;
  position: relative;
  align-items: center;
  align-content: center;
  font-weight: 400;
  min-height: 100%;
}

.typoH2 {
  font-weight: 400;
}

.typoh5 {
  color: #e30000;
}

.deliveryList {
  list-style-type: none;
}

.PaperContainer {
  padding: 1rem;
  display: flex;
  min-height: 100%;
  flex-flow: column wrap;
  max-width: 900px;
  width: 100%;
  align-content: center;
  justify-content: center;
  margin: 10px;
}
.PaperMetods {
  color: #e30000;
  font-family: "Roboto Condensed";
  min-height: 100%;
  font-size: 2rem;
  width: 100%;
  font-weight: 80;
}

.heading {
  font-weight: 700;
  color: #444;
}

.subHeading {
  font-weight: 500;
  color: #444;
}
