@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

html {
  position: relative;
  min-height: 100%;
}

body {
  background: #fff;
}
*,
::after,
::before {
  font-family: "Roboto Condensed", sans-serif;
  box-sizing: border-box;
  font-weight: 400;
}
[class*="socialHrefs"] {
  display: inline-block;
  font-size: 0;
  width: 35px;
  margin: 0 10px;
  height: 35px;
  background: rgba(228, 19, 19, 1);
  position: relative;
  border-radius: 50%;
}

[class*="socialHrefs"]:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
[class*="icon"] {
  padding-left: 25px;
  position: relative;
}
footer {
  height: 90px;
}
[class*="icon"]:before {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  display: inline-block;
  font-size: 0;
  transform: translate(0, 50%);
}

@media (max-width: 820px) {
  .actionFormContainer {
    width: 50%;
    margin-left: -11%;
  }

  footer {
    font-size: 14px;
  }
}
